

interface SSOVerifyReponse {
  ssoEnabled: boolean;
  redirect: string;
}

/**
* Helper for login interactions.
*/
export default abstract class Login {
  // Holds the last username so we do not verify duplicates
  static lastSelectedUsername = '';

  /**
  * AJAX request to check if the login customer username is an SSO login. Redirect to the
  * appropriate SSO site if the user is SSO.
  *
  * @param {string} username Username entered in login screen
  */
  static verifySSOLogin(username: string) {
    if (username !== this.lastSelectedUsername) {
      $.ajax({
        method: 'POST',
        data: {
          user: {
            username
          }
        },
        url: '/login/verify'
      }).done(function (response: SSOVerifyReponse) {
        if (response?.ssoEnabled) {
          window.location.replace(response.redirect);
        }
      });
    }

    this.lastSelectedUsername = username;
  }
}
